import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import {base} from './base';
import ReactGA from 'react-ga';
// import TriviaBigScreenHorizontal from './components/main_screens/TriviaBigScreenHorizontal';
// import TriviaBigScreenVertical from './components/main_screens/TriviaBigScreenVertical';
import BigScreen from './components/main_screens/BigScreen';
import SideBigScreen from './components/main_screens/SideBigScreen';
import './App.css';


if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
  }
}


class App extends Component {
  constructor(props) {
        super(props)
        // this.setCurrentUser = this.setCurrentUser.bind(this)
        this.state = {
          authenticated: false,
          variables: {},
          // currentUser: null,
          loading: true,
        }
    }

    getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

  componentDidMount() {
      // let userEmail;
      // try{
      //     userEmail = localStorage.getItem('userEmail')
      // } catch(e) {
      //     userEmail = false
      // }
      // const htmlParameterEmail = this.getURLParameter("email");
      // if(htmlParameterEmail && this.validateEmail(htmlParameterEmail) && userEmail !== htmlParameterEmail){
      //     const userObject = {};
      //     userObject['lastSignIn'] = new Date().getTime();
      //     userObject['email'] = htmlParameterEmail;
      //     userObject['uid'] = btoa(htmlParameterEmail);
      //     const vm = this;
      //     base.post('users/' + btoa(htmlParameterEmail), {
      //         data: userObject,
      //         context: this,
      //         then(err){
      //             if(!err){
      //                 try {
      //                     localStorage.setItem('userEmail', htmlParameterEmail);
      //                 } catch(e){
      //                     console.log(e)
      //                 }
      //                 vm.getTenantVariables();
      //             }
      //         }
      //     })
      // } else {
          this.getTenantVariables();
      // }
  }

  getTenantVariables(){
      this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
          context: this,
          state: 'tenantVariables',
          then(variables){
              this.setState({
                  authenticated: false,
                  variables: variables,
                  loading: false,
              })
          }
      });
  }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto"}}>
        <BrowserRouter onUpdate={logPageView()}>
            <div>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                          exact
                          path="/login"
                          render={(props) => {
                            return <Login variables={this.state.variables} {...props} />
                          }}
                        />
                          <Route
                              exact
                              path="/bigscreen"
                              render={(props) => {
                                  return <BigScreen variables={this.state.variables} {...props} />
                              }}
                          />
                          <Route
                              exact
                              path="/sidebigscreen"
                              render={(props) => {
                                  return <SideBigScreen variables={this.state.variables} {...props} />
                              }}
                          />
                        {/*<Route*/}
                        {/*  exact*/}
                        {/*  path="/bigscreenhorizontal"*/}
                        {/*  render={(props) => {*/}
                        {/*    return <TriviaBigScreenHorizontal variables={this.state.variables} {...props} />*/}
                        {/*  }}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  exact*/}
                        {/*  path="/bigscreenvertical"*/}
                        {/*  render={(props) => {*/}
                        {/*    return <TriviaBigScreenVertical variables={this.state.variables} {...props} />*/}
                        {/*  }}*/}
                        {/*/>*/}
                        <Route
                          exact
                          path="/"
                          render={(props) => {
                            return <MainApp variables={this.state.variables} {...props} />
                          }}
                        />

                        <Redirect to={`/login`}/>
                      </Switch>
                    </div>
                </div>
            </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
