import React, {Component} from 'react'
import {base} from '../../base';
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import '../../styles/css/react-confirm-alert.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Home.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';

class Login extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try{
          userEmail = localStorage.getItem('userEmail')
        } catch(e) {
          userEmail = false
        }
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputedEmail: '',
          userEmail: userEmail,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

    componentDidMount() {
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(!navigator.cookieEnabled){
        alert("You do not have cookies enabled! Please change the settings of your browser");
        return;
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();
      const htmlParameterEmail = this.getURLParameter("email");
      if(this.state.inputedEmail.trim() === "" && !htmlParameterEmail){
        alert("Please Enter An Email!")
        return;
      }
      if(!this.refs.agree_to_rules_regs.checked){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please agree to rules and regulations!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
        }
      // Play with ' + this.state.inputedEmail + '?'
      var buttons = [
        {
          label: 'NO',
          onClick: () => {
            console.log("Canceled")
            document.body.classList.remove('react-confirm-alert-body-element')
            const target = document.getElementById('react-confirm-alert')
            target.parentNode.removeChild(target)
            const svg = document.getElementById('react-confirm-alert-firm-svg')
            svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur')
          }
        },
        {
          label: "YES",
          onClick: () => {
            this.continueSignInProcess(this.state.inputedEmail);
          }
        }
      ]
      var message = "We'll send your prize there if you win and we don't want to send it to the wrong place\n"+ this.state.inputedEmail + "\nIs that correct?"
      var primaryColor = this.props.variables.primaryColor || "black"
      var secondaryColor = this.props.variables.secondaryColor || "white"
      if(!htmlParameterEmail){
        confirmAlert({
          title: 'Confirm Email',
          message: message,
          customUI: ({ title, message, onClose }) =>
              <div className='react-confirm-alert-body'>
                {title && <h1 style={{fontWeight:'900'}}>{title}</h1>}
                {/*"We'll send your prize there if you win and we don't want to send it to the wrong place"*/}
                <span className="email-in-popup" style={{fontWeight:'900', color:'black', fontFamily:'Roboto'}}>{this.state.inputedEmail}</span>
                <br/>
                <div className='react-confirm-alert-button-group'>
                  {buttons.map((button, i) => (
                      <button
                          key={i}
                          style={{backgroundColor:primaryColor, color: secondaryColor}}
                          onClick={() => {
                            button.onClick()
                          }
                          }
                      >
                        {button.label}
                      </button>
                  ))}
                </div>
              </div>
        })
      } else {
        this.continueSignInProcess(htmlParameterEmail);
      }
    }

    continueSignInProcess(email){
      var base64EncodedEmail = btoa(email);
      var userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = email;
      userObject['uid'] = base64EncodedEmail;
      var vm = this;
      base.post('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            try {
              localStorage.setItem('userEmail', email);
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert("Unable to log you in! Check your settings and try again!")
            }

          } else {
            alert("Oh No! There was an error please try again!")
          }
        }
      })
    }

    render() {
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      const htmlParameterEmail = this.getURLParameter("email");
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const rulesRegsText = tenantRules.rulesAndRegsText || "Please agree to the Rules and Regulations";
      if (redirect || this.state.userEmail) {
          return (
              <Redirect to="/" />
          )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail ? "none":""}}>Enter your email to play</h4>
                    <p className="text-muted" style={{fontFamily: "Roboto", display: htmlParameterEmail ? "none":""}}>So we know where to send your prize</p>
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: htmlParameterEmail ? "none":""}}>
                        <input id="email" name="inputedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="" />
                      </div>
                      <div style={{display:'table-row',textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle'}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{window.open(link, '_blank');}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      {/*<div className="checkbox" style={{padding:'5px'}}>*/}
                      {/*  <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> I have read and agree to the Rules & Regs</label>*/}
                      {/*</div>*/}
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>LET'S PLAY!</strong></button>
                    </form>
                    {/*<button className="btn btn-rules" onClick={()=>{window.open(link, '_blank');}}><strong>Rules & Regs</strong></button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }
}

export default Login;
